<!-- 订单管理  -->
<template>
    <div class="">
      <a-menu v-model="activeTab" mode="horizontal">
        <a-menu-item key="1">全部</a-menu-item>
        <a-menu-item key="2">待审核</a-menu-item>
        <a-menu-item key="3">待领取</a-menu-item>
      </a-menu>
      <!-- table模块 -->
      <!-- 根据tab或者menu的切换只改变表格api的写法关键点就是表格的key要和activeTab的值一致 -->
      <ax-table
        :key="activeTab[0]"
        ref="tableBox"
        :columns="columns"
        :show-search="true"
        :showToolBar="false"
        :searchForm="searchForm"
        :dataSourceApi="dataSourceApis"
        @action-column-click="actionColumnClick"
        :dataSourceParams="dataSourceParams"
        :scroll="{ y: '55vh', x: '80vw' }"
        @pop-confirm="popConfirm"
      >
        <div slot="flag" slot-scope="{ record }">
          <a-button
            :class="{
              gray: record.flag === '2', //已驳回
              green: record.flag === '1', //已通过
              orange: record.flag === '0', //待审核
            }"
          >
            {{
              record.flag === "0"
                ? "待审核"
                : record.flag === "1"
                ? "已通过"
                : "已驳回"
            }}
          </a-button>
        </div>
      </ax-table>
      <!-- 审核弹窗 -->
      <examine ref="examine" @refsh="refsh"></examine>
      <!-- 详情弹窗 -->
      <detaila ref="detaila" @refsh="refsh"></detaila>
    </div>
  </template>
  
  <script>
  import examine from "./components/examine.vue";
  import api from "./api";
  import detaila from "./components/details.vue";
  const searchForm = [
    {
      label: "商品名称",
      type: "input",
      model: "residentName",
      options: {
        placeholder: "请输入",
      },
      formItem: {},
      col: { span: 6 },
    },
    {
      label: "店铺名称",
      type: "",
      model: "gridName",
      options: { placeholder: "请输入" },
      formItem: {},
      col: { span: 6 },
    },
    {
      label: "兑换人",
      type: "input",
      model: "phone3",
      options: { placeholder: "请输入" },
      formItem: {},
      col: { span: 6 },
    },
    {
      label: "所属网格",
      type: "select",
      model: "grid",
      options: { placeholder: "请选择" },
      formItem: {},
      col: { span: 6 },
    },
    {
      label: "订单状态",
      type: "select",
      model: "status",
      options: { placeholder: "请选择" },
      formItem: {},
      col: { span: 6 },
    },
  ];
  
  //这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
  //例如:import <组件名称> from '<组件路径>';
  
  export default {
    //import引入的组件需要注入到对象中才能使用
    components: { examine,detaila },
    data() {
      //这里存放数据
      return {
        api,
        activeTab: ["1"],
        activeTabIndex: ["1"],
        columns: this.$ax.tools.initColumn(
          [
            {
              title: "订单编号",
              dataIndex: "residentName",
              ellipsis: false,
              width: 80,
            },
            {
              title: "商品名称",
              dataIndex: "phone2",
              ellipsis: true,
              width: 190,
            },
            {
              title: "数量",
              dataIndex: "gridName",
              ellipsis: false,
              width: 70,
            },
            {
              title: "实付积分",
              dataIndex: "politicalStatus_dictText",
              ellipsis: true,
              width: 100,
            },
            {
              title: "店铺名称",
              dataIndex: "politicalStatus",
              ellipsis: true,
              width: 100,
            },
            {
              title: "所属网格",
              dataIndex: "updateTime",
              ellipsis: true,
              width: 100,
            },
            {
              title: "订单状态",
              dataIndex: "flag",
              ellipsis: false,
              width: 100,
            },
            {
              title: "兑换人",
              dataIndex: "flag1",
              ellipsis: false,
              width: 100,
            },
            {
              title: "联系电话",
              dataIndex: "phone4",
              ellipsis: false,
              width: 100,
            },
            {
              title: "兑换时间",
              dataIndex: "phone1",
              ellipsis: false,
              width: 100,
            }
          ],
          false,
          {
            // fixed: "right",
            width: 180,
            // 操作部分
            actions: [
              {
                props: (record) => {
                  return {
                    text: "审核",
                    name: "examine",
                    type: "#2A5CFF",
                    link: true,
                    popDisabled: true,
                    // disabled: record.isAbandon === "1",
                  };
                },
              },
              {
                props: (record) => {
                  return {
                    text: "领取",
                    name: "watch",
                    type: "#389E0D",
                    link: true,
                    popDisabled: false, //是否要弹窗
                    title: "确认是否领取该商品(此按钮仅可操作一次,请谨慎操作!)",
                    // disabled: record.isAbandon === "1",
                  };
                },
              },
              {
                props: (record) => {
                  return {
                    text: "详情",
                    name: "watch",
                    type: "#E6A23C",
                    link: true,
                    popDisabled: true, //是否要弹窗
                    // disabled: record.isAbandon === "1",
                  };
                },
              }
            ],
          }
        ),
        searchForm: this.$common.initGridFormData(searchForm, {
          layout: "horizontal",
          labelWidth: 70,
        }),
  
        selectedRowKeys: [],
        dataSourceApis: api.list,
        dataSourceParams: {},
      };
    },
    //监听属性 类似于data概念
    computed: {},
    //监控data中的数据变化
    watch: {},
    //方法集合
    methods: {
      // 表格操作
      async actionColumnClick(args) {
        const {
          btn: { name },
          record,
        } = args;
        switch (name) {
          // 审核
          case "examine":
            this.id = record.id;
            console.log(this.$refs.examine);
            this.$refs.examine.openModal(record);
            break;
          // 详情
          case "watch":
            console.log(this.$refs.detaila);
            
          this.$refs.detaila.openModal(record);
            break;
        }
      },
      // 操作提示
      popConfirm ({ record, btn: { name } }) {
      if (name === 'del') {
        api.del(record.id).then(res => {
          this.getList()
        })
      }
      if (name === 'restPwd') {
        var obj = { id: record.userId }
        api.resetPassword(obj).then(res => {
          this.$message.success('重置密码成功')
        })
      }
    },
      refsh() {
        this.$refs.tableBox.getDataSource();
      },
    },
    //生命周期 - 创建完成(可以访问当前this实例)
    created() {},
    //生命周期 - 挂载完成(可以访问DOM元素)
    mounted() {},
    beforeCreate() {}, //生命周期 - 创建之前
    beforeMount() {}, //生命周期 - 挂载之前
    beforeUpdate() {}, //生命周期 - 更新之前
    updated() {}, //生命周期 - 更新之后
    beforeDestroy() {}, //生命周期 - 销毁之前
    destroyed() {}, //生命周期 - 销毁完成
    activated() {}, //如果页面有keep-alive缓存功能,这个函数会触发
  };
  </script>
  <style lang='less' scoped>
  .orange {
    background-color: #fff8f0;
    color: #e6a23c;
    border: #fff8f0;
    border-radius: 4px;
  }
  .green {
    background-color: #ebf9e6;
    color: #67c23a;
    border-radius: 4px;
    border: #ebf9e6;
  }
  .gray {
    background-color: #e6e9ee;
    color: #2d3138;
    border-radius: 4px;
    border: #e6e9ee;
  }
  /deep/.ant-menu-item-selected {
    border-bottom: 2px solid #2A5CFF !important;
    color: #2A5CFF !important;
  }
  
  /deep/.ant-menu-item:hover {
    border-bottom: 2px solid #2A5CFF !important;
    color: #2A5CFF !important;
  }
  </style>